import { convertToSlug } from '../../utils/utils'
import FieldTypes from './FieldTypes'
import Filters from './components/filters/filters'
import { Grid } from 'ag-grid-community'
import ComplexEvaluations from './ComplexEvaluations'
import CustomTooltip from './CustomTooltip'
import enablePopover from '../fields/popover'

import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.AG_GRID_ENTERPRISE_KEY);
const localStorage = window.localStorage;

class GridFromTable {
  constructor(domElement, options) {
    /*
      options: {
        id: (string) sets the ID of the ag-grid html element
        theme: (string) defaults to alpine theme,
        styles: (string) CSS to be appended to the grid object,
        displayCheckbox: (boolean) displays the checkbox in the first column on the table
      }
    */
    this.DEFAULTS = {
      theme: 'ag-theme-alpine',
      styles: `width: 100%; margin-bottom: 15px; flex-grow: 1;`,
      displayCheckbox: true
    }

    this.setInitialRowPosition(domElement)
    this.options = options
    this.table = domElement

    this.insertedGrid = document.createElement('div')
    this.insertGrid()

    this.data = this.extractDataFromTable(this.table)
    this.clearButtonId = `clear-${this.table.id}`
  }

  setInitialRowPosition(table) {
    Array.from(table.querySelectorAll('tbody tr')).forEach((row, i) => {
      row.setAttribute('data-row-position', i)
    })
  }

  insertGrid() {
    const wrap = $(this.table).parents('.js-ag-grid-wrap')[0]
    if (!wrap) {
      console.warn('Could not add initiate ag-grid because an element with the "js-ag-grid-wrap" was not found. Add that class to a div that wraps the <table> element.')
      return;
    }

    this.insertedGrid.id = this.options.id || ''
    this.insertedGrid.style = this.options.styles || this.DEFAULTS.styles
    this.insertedGrid.classList.add(this.options.theme || this.DEFAULTS.theme)
    wrap.insertAdjacentElement('afterend', this.insertedGrid)
  }

  getHeadings(table) {
    if (table.classList.contains('ag-grid-nested')) {
      let tableHeadings = table.getAttribute('data-headers');
      if (tableHeadings) tableHeadings = JSON.parse(tableHeadings);
      let headers = [];
      const displayCheckbox = !!JSON.parse(table.dataset.displayCheckbox || 'false')
      for (let key in tableHeadings) {
        let header = ComplexEvaluations.createHeader(key, tableHeadings[key], displayCheckbox && !headers.length)
        headers.push(header);
      }
      if (headers.length > 0) return headers;
    } else {
      const tableHeadings = table.querySelectorAll('th')

      return Array.from(tableHeadings).map((head, i) => {
        const {
          gridFieldType = 'default',
          gridUpdatePath = '',
          gridFieldName = '',
          gridUpdateModel,
          gridTagPath,
          gridFilterType = 'Text'
        } = head.dataset

        const displayCheckbox = !!JSON.parse(table.dataset.displayCheckbox || 'false') && i == 0
        const fieldName = convertToSlug(head.innerText) || `unnamed_${i}`

        let heading = {
          headerName: head.innerText,
          field: fieldName,
          headerClass: head.getAttribute('class'),
          cellClass: head.getAttribute('class'),
          checkboxSelection: displayCheckbox,
          headerCheckboxSelection: displayCheckbox,
          headerCheckboxSelectionFilteredOnly: displayCheckbox
        }

        if (fieldName === 'icon') {
          heading.headerComponentParams = { template: `<div class="btn btn-sm w-100"><i class="${$(head).data('icon')}"></i></div>` }
          heading.headerTooltip = $(head).data('tooltip')
        }

        if (Object.keys(FieldTypes).includes(gridFieldType)) {
          const tableUpdateModel = table.dataset.gridUpdateModel

          Object.assign(heading, {
            ...FieldTypes[gridFieldType],
            cellEditor: `${gridFieldType}`,
            editable: !!FieldTypes[gridFieldType].editor,
            gridFieldName: gridFieldName,
            gridTagPath: gridTagPath,
            gridUpdatePath: gridUpdatePath || table.dataset.gridUpdatePath,
            gridUpdateModel: gridUpdateModel || tableUpdateModel,
            // a column might have a different model than the table (e.g. tags),
            // we still want to keep track of the table's model though
            tableUpdateModel: tableUpdateModel,
          })

          if (gridFilterType) {
            Object.assign(heading, {
              filter: Filters[gridFilterType],
              filterParams: {
                defaultOption: gridFilterType == 'Text' ? 'contains' : 'greaterThan',
                suppressAndOrCondition: true,
              }
            })
          }
        }

        return heading;
      })
    }
  }

  extractDataFromTable(table) {
    const tableRows = table.querySelectorAll('tbody tr')
    const footerRow = table.querySelectorAll('tfoot tr')
    const headings = this.getHeadings(table)
    const headerName = table.getAttribute('data-header-name');
    const layout = table.getAttribute('data-layout');
    const lastRow = table.getAttribute('data-last-row')
    const nestedGrid = table.classList.contains('ag-grid-nested')
    let columnIndex;
    let assignColumnDataFromTable;
    if (nestedGrid) {
      assignColumnDataFromTable = (header, columns, i, info) => {
        if (header.children) {
          header.children.forEach((_, index) => {
            assignColumnDataFromTable(header.children[index], columns, columnIndex, info)
          })
        } else {
          if (columns[columnIndex] && columns[columnIndex].innerHTML !== '') {
            Object.assign(info, {
              [header.field]: columns[columnIndex].innerHTML,
            })
          }
          columnIndex = i + 1
        }
      }
    } else {
      assignColumnDataFromTable = (column, i, info) => {
        Object.assign(info, {
          [headings[i].field]: column.innerHTML,
        })
      }
    }

    return {
      headerName: headerName,
      headerField: lastRow,
      headings: headings,
      layout: layout,
      rows: Array.from(tableRows).map((row, rowIndex) => {
        const columns = row.querySelectorAll('td')
        const info = {
          rowPosition: rowIndex,
          tagId: row.dataset.tagParentId,
          bulkEditId: row.dataset.bulkEditId,
          id: row.dataset.id,
          parentIdEntries: [],
          evalIdEntries: [],
          associatedWithEntries: [],
          scoreTypeEntries: [],
          maxScoreEntries: [],
          weightEntries: [],
          tooltipContent: [],
          responses: [],
        }

        if (nestedGrid) {
          columns.forEach((column) => {
            info.parentIdEntries.push(column.getAttribute('data-id'))
            info.evalIdEntries.push(column.getAttribute('data-eval-id'))
            info.associatedWithEntries.push(column.getAttribute('data-associated-with'))
            info.scoreTypeEntries.push(column.getAttribute('data-score-type'))
            info.maxScoreEntries.push(column.getAttribute('data-max-score'))
            info.weightEntries.push(column.getAttribute('data-weight'))
            info.tooltipContent.push(column.getAttribute('data-tooltip-content'))
            info.responses.push(column.getAttribute('data-responses'))
          })
          columnIndex = 0
          headings.forEach((header) => {
            assignColumnDataFromTable(header, columns, columnIndex, info);
          })
        } else {
          columns.forEach((column, i) => {
            assignColumnDataFromTable(column, i, info)
          })
        }

        return info
      }),
      footer: Array.from(footerRow).map((row, rowIndex) => {
        const columns = row.querySelectorAll('td')
        const info = {
          rowPosition: rowIndex,
          tagId: row.dataset.tagParentId,
          bulkEditId: row.dataset.bulkEditId,
          id: row.dataset.id
        }

        columns.forEach((column, i) => {
          assignColumnDataFromTable(column, i, info)
        })

        return info
      }),
    }
  }

  clearBulkSelect2Field(table) {
    $(`select[data-populate-from="#${table.id}"`).val([]).trigger('change');
  }

  clearFilters(e) {
    const anchor = $(e.target).parents('a[name]').attr('name')

    if (!anchor) {
      console.warn('Could not scroll to anchor – An anchor tag not found for the selected table.')
    } else {
      window.location = `${window.location.pathname}#${anchor}`
    }
    this.clearBulkSelect2Field(this.table)

    // query the dom to grab the table again (in case it has
    // new data from action cable), instead of reusing this.table
    const randomId = Math.floor((Math.random() * 100) + 1);
    const gridFromTable = new GridFromTable(document.querySelector(`#${this.table.id}`), {
      id: `grid-${randomId}`
    })

    gridFromTable.initGrid()

    $('#' + this.insertedGrid.id).remove()
  }

  autoSizeAll(gridOptions) {
    var allColumnIds = []
    gridOptions.api.getColumns().forEach(function (column) {
      if (!column.colDef.disableAutoSize) {
        allColumnIds.push(column.colId)
      }
    });

    gridOptions.api.autoSizeColumns(allColumnIds, false)
  }

  initGrid() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const evaluation_id = url.pathname.split('/')[3];
    if (!localStorage.getItem(`status_${evaluation_id}`)) {
      localStorage.setItem(`status_${evaluation_id}`, []);
    }
    const stripHtml = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");
    const isNumeric = (str) => {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
             !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    let defaultColDef = {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      tooltipComponent: CustomTooltip,
      comparator: (valueA, valueB) => {
        valueA = stripHtml(valueA).replace('%','')
        valueB = stripHtml(valueB).replace('%','')
        const valuesAreNumbers = isNumeric(valueA) && isNumeric(valueB)
        if (valuesAreNumbers)  {
          return Number(valueA) - Number(valueB)
        } else {
          return valueA.localeCompare(valueB)
        }
      }
    }

    let gridOptions = {
      autoGroupColumnDef: {
        headerName: this.data.headerName,
        headerTooltip: 'Eval Name',
        tooltipValueGetter: (params) => {
          return params.value;
        },
        field: this.data.headerField,
        minWidth: 200,
        sortable: false,
        filter: false,
        menuTabs: ['generalMenuTab'],
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: function(params) {
            return params.value;
          }
        },
      },
      columnDefs: this.data.headings,
      defaultColDef: defaultColDef,
      domLayout: this.data.layout || 'autoHeight',
      pinnedBottomRowData: this.data.footer,
      rowData: this.data.rows,
      rowSelection: 'multiple',
      singleClickEdit: true,
      getMainMenuItems: getMainMenuItems,
      suppressPropertyNamesCheck: true,
      suppressRowClickSelection: true,
      suppressColumnVirtualisation: true,
      suppressAggFuncInHeader: true,
      suppressFieldDotNotation: true,
      tooltipShowDelay: 0,
      tooltipMouseTrack: true,
      getGroupRowAgg: _getGroupRowAgg,
      groupAllowUnbalanced: true,
      groupSuppressBlankHeader: true,
      initialGroupOrderComparator: (params) => {
        const a = _getLowestRowPositionFromNode(params.nodeA);
        const b = _getLowestRowPositionFromNode(params.nodeB);

        return a < b ? -1 : a > b ? 1 : 0;
      },
      onSelectionChanged: (event) => {
        const selections = event.api.getSelectedNodes().map((selected) => selected.data.bulkEditId);
        $(`select[data-populate-from="#${this.table.id}"]`).val(selections).trigger('change');
        (selections.length) ? $('.bulk-actions').removeClass('d-none') : $('.bulk-actions').addClass('d-none');
      },
      onFilterChanged: (event) => {
        // when a filter is set, we want to clear all nodes that weren't filtered
        const selected = event.api.getSelectedNodes()
        event.api.deselectAll()

        event.api.forEachNodeAfterFilter(function(node) {
          // check it off again, if it makes it passed the filter
          if (selected.includes(node)) {
            node.setSelected(true)
          }
        });
      },
      onGridReady: () => {
        var clearButton = this.insertedGrid.querySelector('#' + this.clearButtonId);
        if (clearButton) {
          clearButton.addEventListener('click', (e) => {
            this.clearFilters(e)
          })
        }

        setExpandedRows();

        $('.ag-center-cols-container .ag-row-footer').each(function() {
          if ($(this).hasClass('ag-row-last')) return;

          $(this).find('.complex-evaluation-cell').each(function() {
            $(this).addClass('d-none')
          })
        })

        if (this.table.classList.contains('columns-full-w')) {
          this.autoSizeAll(gridOptions);
        }

        $('.section-forms-popover').each(function() {
          $(this.closest('.ag-group-value')).addClass('w-100')
        })

        enablePopover();
      },
      onColumnResized: () => {
        gridOptions.api.stopEditing();
      },
      onRowGroupOpened: (row) => {
        let status_array = localStorage.getItem(`status_${evaluation_id}`).split(',');
        if (row.node.aggData) {
          if (row.node.expanded) {
            if (!status_array.includes(row.node.aggData.id)) {
              if (status_array.length === 0) {
                localStorage.setItem(`status_${evaluation_id}`, [row.node.aggData.id]);
              } else {
                localStorage.setItem(`status_${evaluation_id}`, [...status_array, row.node.aggData.id]);
              }
            }
          } else {
            if (status_array.includes(row.node.aggData.id)) {
              status_array = status_array.filter((status) => status != row.node.aggData.id);
              localStorage.setItem(`status_${evaluation_id}`, status_array);
            }
          }
        }

        $('.section-forms-popover').each(function() {
          $(this.closest('.ag-group-value')).addClass('w-100')
        })
      },
      getRowId: function (params) {
        return params.data.id;
      },
      onColumnGroupOpened: () => {
        const pinnedRow = '.ag-pinned-left-header .ag-header-row.ag-header-row-column-group';
        const rowColumnsGroup = '.ag-header-container .ag-header-row.ag-header-row-column-group';
        const headerRow = $('.ag-header-container .ag-header-row.ag-header-row-column')[0];
        const headerContainer = $('.ag-header.ag-focus-managed.ag-pivot-off')[0];
        const pinnedRowAndRowColumnsGroup = $(`${pinnedRow}, ${rowColumnsGroup}`);

        let fullRows = 0;
        pinnedRowAndRowColumnsGroup.each((_, value) => {
          let fullRow = false;
          const columns = value.querySelectorAll('.ag-header-group-text');
          columns.forEach((value) => {
            if (value.innerHTML) {
              fullRow = true
            }
          })
          if (fullRow) fullRows += 1
        })
        headerRow.style.top = `${48 * (fullRows)}px`;
        this.autoSizeAll(gridOptions);
        headerContainer.style.height = `${48 * (fullRows + 1)}px`;
        headerContainer.style.minHeight = `${48 * (fullRows + 1)}px`;
      },
      onCellClicked: () => {
        enablePopover();
      },
      components:
        /* Gather the components from FieldTypes,
        mutate them so we can register them as ag-grid components here. */
        Object.fromEntries(Object.keys(FieldTypes).map((field) => {
          return [field, FieldTypes[field].editor]
        }))
    }

    function setExpandedRows() {
      const status = localStorage.getItem(`status_${evaluation_id}`);
      gridOptions.api.forEachNode(node => {
        if (status.length > 0 && node.aggData && status.includes(node.aggData.id)) {
          node.setExpanded(true);
        }
      });
    }

    let grid = new Grid(this.insertedGrid, gridOptions)

    if (!this.table.classList.contains('columns-full-w')) {
      this.autoSizeAll(grid.gridOptions)
    }

    return { [this.table.id]: grid }
  }

  // public
  static extract(table) {
    return this.prototype.extractDataFromTable(table)
  }
}

function _getGroupRowAgg(params) {
  return groupRowAggNodes(params.nodes)
}

function groupRowAggNodes(nodes) {
  let result = {
    'id': '',
    'Eval Name': '',
    'Associated With': '',
    'Instructions for Evaluator': 'N/A',
    'Score Type': '',
    'Max Score': '',
    'Weighting': '',
    'Justification Req': 'N/A',
  };

  let table = $('table');
  let teamHeaders;

  if (table[0].classList.contains('nested-evaluations-summary')) {
    let tableHeaders = Object.keys(JSON.parse(table.attr('data-headers')));
    let index = tableHeaders.indexOf('Row');
    teamHeaders = tableHeaders.slice(index + 1);
  }

  if (nodes.length) {
    let node = nodes.at(-1);
    let data;

    if (node.group) {
      data = node.allLeafChildren[0].data;
    } else {
      data = node.data;
    }

    if (data) {
      result['id'] = data.parentIdEntries[node.level];
      result['Associated With'] = data.associatedWithEntries[node.level];
      result['Score Type'] = data.scoreTypeEntries[node.level];
      result['Max Score'] = data.maxScoreEntries[node.level];
      result['Weighting'] = data.weightEntries[node.level];
      addAggregateValues(teamHeaders, data.responses, node.level - 1, result)
    } else if (node.allLeafChildren) {
      addAggregateValues(teamHeaders, data.responses, node.level, result)
    }
  }

  return result;
}

function addAggregateValues(headers, responses, index, result) {
  if (headers && responses) {
    if (!responses[index]) return;
    const responsesArray = JSON.parse(responses[index]);
    if (!responsesArray) return;

    headers.forEach((header, i) => {
      const response = JSON.parse(responsesArray[i])[0];
      result[header] = response ? response.show_value : '';
    })
  }

  return result
}

function getMainMenuItems(params) {
  const menuItems = [];
  const itemsToExclude = $('.ag-grid-nested').length ? ['separator', 'autoSizeThis', 'autoSizeAll', 'resetColumns'] : [];
  params.defaultItems.forEach((item) => {
    if (itemsToExclude.indexOf(item) < 0) {
      menuItems.push(item);
    }
  });
  return menuItems;
}

function _getLowestRowPositionFromNode(row) {
  if (row.childrenAfterGroup) {
    let lowestVal = undefined;
    row.childrenAfterGroup.forEach((child) => {
      const lowestId = _getLowestRowPositionFromNode(child);
      if (lowestVal === undefined || lowestId < lowestVal) {
        lowestVal = lowestId;
      }
    });
    return lowestVal;
  }
  return row.data.rowPosition;
}

export default GridFromTable
