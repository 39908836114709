import TagEditor from '../TagEditor'

class ModelFilter {
  constructor() {
    this.params = null
    this.filterOptions = []
    this.filterOptionIds = []
    this.selections = []
  }

  createFormOptions() {
    console.log(this)
    return this.filterOptions && this.filterOptions.map((option) => {
      const { id, color, text } = option
      const setDefaultColor = !!color
      return `
        <div data-text="${text}">
          <span class="badge" style="background: ${color}">
            <label><input type="checkbox" ${ this.selections.includes(id) ? `checked` : '' } class="filter-option" name="${this.columnType}" value="${id}" /> ${TagEditor.dataToHtml(option, setDefaultColor)}</label>
          </span>
          </div>` 
    }).join('')
  }

  getFilterOptionsFromNodes() {
    this.filterOptions = []
    this.filterOptionIds = []
    const { field } = this.params.colDef

    this.params.api.forEachNode((node) => {
      const nodeData = node.data[field]
      if (nodeData && nodeData.includes('filter-option')) {
        const tags = TagEditor.HtmlToData(nodeData)
        // only add unique tags to filter options
        tags.forEach((tag) => {
          if (!this.filterOptionIds.includes(tag.id)) {
            this.filterOptionIds.push(tag.id)
            this.filterOptions = this.filterOptions.concat(tag)
          }
        })
      }
    })
  }

  searchFilterFormOptions(value) {
    const optionElement = Array.from(this.filterElement.querySelectorAll('div[data-text]'))
    optionElement.forEach((option) => {
      if (value.length > 0 && !option.querySelector('input[type="checkbox"]').checked) {
        option.style.display = option.dataset.text.toLowerCase().includes(value.toLowerCase()) ? 'block' : 'none'
      } else {
        option.style.display = 'block'
      }
    })

    this.selections = Array.from(this.filterElement.querySelectorAll(`input[name="${this.columnType}"]:checked`)).map((checked) => checked.value)
    this.active = this.selections.length > 0
    this.filterChangedCallback();
  }

  setFilterElementHtml() {
    this.getFilterOptionsFromNodes()
    
    this.filterElement.innerHTML = `
      <div class="filter-head">
        <div class="filter-head-title">Filter</div>
        <div class="text-right"><button class="btn btn-sm btn-secondary" id="clear">Clear search</button></span></div>
      </div>
      <hr style="margin: 0 0 10px"/>
      <div class="element-search autosuggest-search-activator" data-children-count="1" style="margin: 0 10px">
        <input placeholder="Start typing to search..." id="search" />
      </div>
      <form id="tag-filter-wrap">
        ${
          this.createFormOptions()
        }
      </form>
    </div>`

    this.attachFilterElementHandlers()
  }

  attachFilterElementHandlers() {
    $(this.filterElement.querySelector('#search')).on('change keyup', (e) => this.searchFilterFormOptions(e.target.value))

    this.filterElement.querySelector('#clear').addEventListener('click', () => {
      Array.from(this.filterElement.querySelectorAll(`input[name="${this.columnType}"]:checked`)).forEach((selected) => {
        selected.checked = false
      })
      this.filterElement.querySelector('#search').value = ''
      this.searchFilterFormOptions([])
    })

    this.active = false
    this.selections = []
    this.filterSelect = this.filterElement.querySelector('#tag-filter-wrap')
    this.valueGetter = this.params.colDef.filterValueGetter;
    this.filterChangedCallback = this.params.filterChangedCallback;

    this.filterSelect.addEventListener('change', () => {
      this.searchFilterFormOptions(this.filterElement.querySelector('#search').value)
    });
  }

  init(params) {
    this.params = params
    this.filterElement = document.createElement('div');
    this.setFilterElementHtml()
  }

  getGui() {
    this.setFilterElementHtml()
    return this.filterElement
  }

  isFilterActive() {
    return this.active
  }

  doesFilterPass(params) {
    const rowTags = TagEditor.HtmlToData(this.valueGetter(params)).map((x) => x.id)
    return this.selections.every(v => rowTags.indexOf(v) !== -1)
  }

  getModel() {
  }

  setModel() {
  }
}

export default ModelFilter;
