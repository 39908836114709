class CustomTooltip {
  init(params) {
    const eGui = this.eGui = document.createElement('div');
    eGui.classList.add('ag-tooltip');

    if (params.column.colId === 'icon' || params.colDef.headerName == 'Eval Name') {
      eGui.innerHTML = params.value;
    } else if (params.column.colId == 'Team' || params.column.colId == 'Response') {
      let value = $(params.value)[0].innerHTML;
      eGui.innerHTML = `<p>${value}</p>`;
    } else if (params.column.colDef.tooltipField) {
      const columns = params.api.getColumns();
      const colIndex = columns.indexOf(params.column);
      const tooltipContent = params.node.data.tooltipContent[colIndex];
      if (tooltipContent) {
        eGui.innerHTML = tooltipContent;
      } else {
        eGui.style['display'] = 'none';
      }
    }
  }

  getGui() {
    return this.eGui;
  }
}

export default CustomTooltip;
